import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, Button
} from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import GridForm from "./grid";
import { XTextBox } from "../../components/common/inputs/XTextBox";
import { Form, Formik } from "formik";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const Create = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [grid, setGrid] = useState<any>([]);
  const [selectedMarkTypes, setSelectedMarkTypes] = useState<any>([]);

  const params = new URLSearchParams(useLocation().search);

  const [initialValues, setInitialValues] = useState<any>({
  });

  const request = new ApiCore();

  const loadRecords = (values: any, selectedTypes: any) => {
    setInitialValues(values);
    setSelectedMarkTypes(selectedTypes);
    const responseType = (values.excel && values.excel == 1) ? "arraybuffer" : "json";
    toast.promise(request.createFormData(Endpoints.attendances, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          if (values.file) {
            message = data?.data.message;
          } else if (values.excel && values.excel == 1) {
            message = "The excel file is ready."
          }
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      if (values.file) {
        //Nothing to do here
      } else if (values.excel && values.excel == 1) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `marks-${values.form}-${values.stream}-${values.year}-${values.term}.xlsx`
        link.click()
      } else {
        const data = response.data;
        updateGrid(data, selectedTypes, values);
      }
    });
  }

  const handleSubmit = (values: any) => {
    setLoading(true);
    setInitialValues(values);
    request
      .create(Endpoints.attendances, values)
      .then((response: AxiosResponse) => {
        const data = response.data;
        toast.success(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false)
        toast.error(error);
      });
  }

  const updateGrid = (data: any, types: any, values: any) => {
    let daysattended: any = {};

    data.forEach((element: any) => {
      daysattended[`sid_${element.student.studentid}`] = element.daysattended;
    });
    setInitialValues({ ...values, daysattended: daysattended });
    setGrid(data);
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Student Attendance - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Marks" breadcrumbItem="List" />
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Class attendance
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#">
                        Record Attendance
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading attendance. Please wait." />}
                  {!loading &&
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <GridForm
                            initialValues={initialValues}
                            handleSearch={(values: any, selectedTypes: any) => {
                              delete values.daysattended;
                              loadRecords(values, selectedTypes);
                            }}
                          />
                          <ToastContainer />
                        </Col>
                      </Row>
                      {grid.length > 0 &&
                        <Formik
                          initialValues={initialValues}
                          enableReinitialize
                          validateOnBlur
                          onSubmit={(values: any) => handleSubmit(values)}
                        >
                          {({ isSubmitting, setFieldValue, values, errors, touched }) => (
                            <>
                              <Form>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Days attended</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {grid.map((item: any, index: number) => {
                                      return <tr key={index}>
                                        <td>{item.student.fullname}</td>
                                        <td>
                                          <XTextBox
                                            type="number"
                                            key={`daysattended.sid_${item.student.id}`}
                                            id={`daysattended.sid_${item.student.id}`}
                                            name={`daysattended[sid_${item.student.id}]`}
                                            touched={touched}
                                          />
                                        </td>
                                      </tr>
                                    })}
                                  </tbody>
                                </table>
                                <div>
                                  <Row>
                                    <Col lg={2}>
                                      <XTextBox
                                        type="number"
                                        key="totaldays"
                                        id="totaldays"
                                        name={"totaldays"}
                                        label={"Total Days"}
                                        touched={touched}
                                        errors={errors}
                                      />
                                    </Col> 
                                    <Col lg={2}>
                                      <Button
                                        disabled={false}
                                        type="submit"
                                        name="btn-submit"
                                        className="btn btn-primary"
                                      >
                                        SAVE
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Form>
                              <hr />
                            </>
                          )}
                        </Formik>
                      }
                    </>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Create;